import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import './FillInBlanks2.scss';
import { useState } from 'react';
import StyledButton from '../../../components/StyledButton/StyledButton';
import AudioBox from '../../../components/Audio/Audio';

function FillInBlanks2({ ex }) {
  const [fillAnswer, setFillAnswer] = useState([]);
  const [result, setResult] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const [showAnswers, setShowAnswer] = React.useState(false);

  React.useEffect(() => {
    setFillAnswer([]);
    setResult([]);
    setIsCheck(false);
    setShowAnswer(false);
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  const handleFillAnswer = (e, questionId, index) => {
    const answerArray = [];
    answerArray[index] = e.target.value.toLowerCase();
    setFillAnswer((prevState) => {
      if (prevState.length <= 0) {
        return [
          {
            id: questionId,
            data: answerArray,
          },
        ];
      } else {
        const updatedAnswer = prevState;
        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswer.push({
            id: questionId,
            data: answerArray,
          });
        } else {
          updatedAnswer.map((answer) => {
            if (answer.id === questionId) {
              answer.data[index] = e.target.value.toLowerCase();
              answer = {
                ...answer,
                data: answer.data,
              };
            }
            return answer;
          });
        }
        return updatedAnswer;
      }
    });
  };

  const handleCheckAnswer = () => {
    setIsCheck(true);
    const answers = ex.questions.map((item) => ({ id: item.id, answer: item.answer.split(',') }));

    const result = answers.map((fillItem) => {
      const answerItem = fillAnswer.find((item) => item.id === fillItem.id);

      if (answerItem) {
        const differences = fillItem.answer.map((value, index) => {
          return value !== answerItem.data[index] ? 'false' : 'true';
        });

        return {
          id: fillItem.id,
          answer: differences,
        };
      }

      return {
        id: fillItem.id,
        answer: fillItem.answer.map(() => 'false'),
      };
    });
    setResult(result);
  };

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div id="fill-in-blanks-2-wrapper">
        {ex.questions.map((question) => {
          let contentItems = question.content.split('_');
          if (contentItems[0] === '') contentItems = question.content.split('_').slice(1);

          return (
            <div
              key={question.id}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: '4rem',
              }}
            >
              <Typography
                variant="body"
                marginRight="1.2rem"
                width="2.8rem"
                height="2.8rem"
                sx={{
                  backgroundColor: '#000',
                  color: '#fff',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                }}
              >
                {question.ordinalNumber}
              </Typography>
              {question.content.split('_')[0] === ''
                ? contentItems.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1.2rem' }}
                    >
                      <input
                        disabled={isCheck}
                        type="text"
                        className={`input ${
                          isCheck
                            ? result.some(
                                (item) => item.id === question.id && item.answer[index] === 'true',
                              )
                              ? 'true'
                              : 'false'
                            : ''
                        }`}
                        onChange={(e) => handleFillAnswer(e, question.id, index)}
                      />
                      <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                        {item}
                      </Typography>
                    </Box>
                  ))
                : contentItems.map((item, index) => {
                    if (index !== contentItems.length - 1)
                      return (
                        <Box
                          key={index}
                          sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1.2rem' }}
                        >
                          <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                            {item}
                          </Typography>
                          <input
                            disabled={isCheck}
                            type="text"
                            className={`input ${
                              isCheck
                                ? result.some(
                                    (item) =>
                                      item.id === question.id && item.answer[index] === 'true',
                                  )
                                  ? 'true'
                                  : 'false'
                                : ''
                            }`}
                            onChange={(e) => handleFillAnswer(e, question.id, index)}
                          />
                        </Box>
                      );
                    else {
                      return (
                        <Box key={index}>
                          <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                            {item}
                          </Typography>
                        </Box>
                      );
                    }
                  })}
            </div>
          );
        })}
        <Stack direction="row" className="button">
          <StyledButton onClick={handleCheckAnswer} disabled={isCheck} content="Kiểm tra" />
          <div style={{ marginLeft: '2rem' }}>
            <StyledButton
              onClick={() => setShowAnswer(!showAnswers)}
              content={!!showAnswers ? 'Đóng đáp án' : 'Xem đáp án'}
              type="border-accent"
            />
          </div>
        </Stack>
        {!!showAnswers &&
          ex.questions.map((question) => {
            const answerList = question.answer.split(',');
            return (
              <div
                item
                xs={6}
                md={6}
                lg={6}
                key={question.id}
                style={{ marginTop: '2rem', display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  variant="body"
                  marginRight="1.2rem"
                  width="2.8rem"
                  height="2.8rem"
                  sx={{
                    backgroundColor: '#000',
                    color: '#fff',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                  }}
                >
                  {question.ordinalNumber}
                </Typography>
                {answerList.map((item, index) => (
                  <Typography variant="body" key={index}>
                    {item}
                    {index !== answerList.length - 1 && '，'}
                  </Typography>
                ))}
              </div>
            );
          })}
      </div>
    </>
  );
}

export default FillInBlanks2;
